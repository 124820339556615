import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { AppContext, AppInitialProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../hooks/apolloClient';
import NextNprogress from 'nextjs-progressbar';
import { AppWrapper } from '../context/State';
import GlobalAppThemeProvider from '@/components/GlobalAppTheme';
// import { GoogleAnalytics } from 'nextjs-google-analytics';
// import ReactGA from 'react-ga4';

// import LiveChatLoader from '../components/LiveChat';
// import { analytics } from '../utils/analytics';
import useScrollRestoration from '../hooks/useScrollRestoration';
import Script from 'next/script';
const LiveChatLoader = dynamic(() => import('../components/LiveChat'), { ssr: false });
import '../../public/styles/tailwind.css';
import '../../public/styles/sass/globals.sass';

function ReactPressApp({ Component, pageProps }: AppContext & AppInitialProps) {
    const apolloClient = useApollo(pageProps);
    const [interaction, setInteraction] = useState(false);
    const router = useRouter();
    useScrollRestoration(router);

    function eventFire() {
        if (!interaction) {
            setInteraction(true);
            // analytics(window, document, 'script', 'dataLayer', 'GTM-PPSZD3X');
            // console.log('event fired');
        }
    }

    function addListenerMulti(element, allEvents, listener) {
        for (let i = 0, iLen = allEvents.length; i < iLen; i++) {
            element.addEventListener(allEvents[i], listener, false);
        }
    }

    // usePageViews();
    useEffect(() => {
        // analytics(window, document, 'script', 'dataLayer', 'GTM-PPSZD3X');

        const hash = window.location.hash;
        const allEvents =
            'click mousedown mouseup focus blur keydown change dblclick mousemove mouseover mouseout mousewheel keydown keyup keypress textInput touchstart touchmove touchend touchcancel resize zoom select change submit reset'.split(
                ' '
            );

        if (!interaction) addListenerMulti(window, allEvents, eventFire);

        if (hash && !hash.includes('=')) {
            setTimeout(() => {
                document?.querySelector(hash)?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }
    }, [eventFire]);

    return (
        <AppWrapper>
            <ApolloProvider client={apolloClient}>
                <GlobalAppThemeProvider>
                    <NextNprogress color="#ccc" height={7} showOnShallow={false} />
                    {/* <GoogleAnalytics trackPageViews strategy="afterInteractive" gaMeasurementId="UA-937473-39" /> */}
                    <Component {...pageProps} />
                    {interaction && <LiveChatLoader />}
                    {/* {interaction && (
                    <Script
                        id="googleTagManager"
                        strategy="lazyOnload"
                        type="text/partytown"
                        dangerouslySetInnerHTML={{
                            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer', 'GTM-PPSZD3X');`
                        }}
                    />
                )} */}
                    {interaction && process.env.NEXT_PUBLIC_WP.includes('https://v2-live-wp.jewsforjesus.org') && (
                        <Script
                            strategy="lazyOnload"
                            // type="text/partytown"
                            dangerouslySetInnerHTML={{
                                __html: `(function(c,l,a,r,i,t,y){
                                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                            t=l.createElement(r);t.defer=1;t.src="https://www.clarity.ms/tag/"+i;
                                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                                        })(window, document, "clarity", "script", "i0tksnj12q");`
                            }}
                        />
                    )}
                </GlobalAppThemeProvider>
            </ApolloProvider>
        </AppWrapper>
    );
}

export default ReactPressApp;
